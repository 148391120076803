import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyC0b392CtUZPdjQxlc95VSR1wzFaYRiTYM",
  authDomain: "lp-ai-delivery.firebaseapp.com",
  projectId: "lp-ai-delivery",
  storageBucket: "lp-ai-delivery.appspot.com",
  messagingSenderId: "307060508897",
  appId: "1:307060508897:web:b364b6cb16e5caa7118fa0",
  measurementId: "G-D6PT3G6MZR"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const firestore = getFirestore();

export const functions = getFunctions(app);

const googleProvider = new GoogleAuthProvider();

export { app, auth, firestore, analytics, googleProvider, collection, doc, getDoc, getDocs, query, where };
