import React, { useState, useEffect } from 'react';
import { auth, googleProvider, app, functions, firestore, collection, doc, getDoc, getDocs, query, where } from './firebaseConfig';
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { OpenAIClient, AzureKeyCredential } from "@azure/openai";
import { httpsCallable } from 'firebase/functions';

function LoadingSpinner() {
  return <div className="spinner"></div>;
}

const retrieveGeneratedLPs = async (userId) => {
  const lpRef = firestore.collection('generatedLPs').doc(userId);
  const doc = await lpRef.get();

  if (doc.exists) {
    return doc.data().lps; // Assuming the field in the document is named 'lps' and contains an array of LPs
  } else {
    console.log("No such document!");
    return [];
  }
}

const listenForLPUpdates = async (userId, setGeneratedLPs) => {
  if (!userId) return; // If the user is not logged in, don't listen for updates

  const q = query(collection(firestore, "generatedLPs"), where("userId", "==", userId));
  const lpRef = await getDocs(q);
  const data = []
  const unsubscribe = lpRef.forEach((doc) => {
    data.push(doc.data())
  });
  setGeneratedLPs(data); // Update the local state with the new LPs
  return unsubscribe; // You can call this function to stop listening for updates
}


function App() {
  const [description, setDescription] = useState('');
  const [generatedLP, setGeneratedLP] = useState('');
  const [user, setUser] = useState(null);  // Add state to store user info
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedLPs, setGeneratedLPs] = useState([]);
  const [showLP, setShowLP] = useState(null);
  const showPopup = (lpContent) => {
    setShowLP(lpContent);
  };


  useEffect(() => {
    // Watch for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      listenForLPUpdates(currentUser?.uid, setGeneratedLPs);
    });
    return () => unsubscribe();  // Clean up listener on component unmount
  }, []);


  const handleSignIn = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, googleProvider);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await signOut(auth);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const generateLPFunction = httpsCallable(functions, 'generateLP');
  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const result = await generateLPFunction({ description: description });
      // const lpData = result.data.lpData;
      setGeneratedLPs((prev) => [...prev, result.data])
    } catch (error) {
      console.error('Error generating LP:', error);
      alert(error.message);
    }

    setIsLoading(false);
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // This callback will run on initial load, and anytime the user's auth state changes
      setLoading(false);  // We know the user's auth state now, so we can stop showing the loading spinner
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    // Display a loading spinner or some other placeholder
    return <LoadingSpinner />;
  }


  if (!user) {  // If user is not authenticated
    return (
      <div class="bg-gray-100 text-gray-900">
        <header class="bg-white p-4 shadow-md">
          <nav class="container mx-auto flex justify-between items-center">
            <a href="#" class="text-2xl font-bold">AILPC</a>
            <div>
              <a href="#features" class="ml-4">特長</a>
              <a href="#testimonials" class="ml-4">お客様の声</a>
              <a href="#faq" class="ml-4">FAQ</a>
            </div>
          </nav>
        </header>

        <section class="bg-white dark:bg-gray-900 py-16">
          <div class="container mx-auto text-center">
            <h1 class="text-4xl font-extrabold mb-8 text-white">AIであなたのランディングページを自動生成</h1>
            <p class="text-lg text-white mb-8">最先端の技術を活用して、魅力的なLPを短時間で作成。ビジネスを加速させます。</p>
            <button
              onClick={handleSignIn}
              href="#" class="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600">Googleではじめる</button>
          </div>
        </section>

        <section id="features" class="py-16">
          <div class="container mx-auto text-center">
            <h2 class="text-3xl font-bold mb-8">特長</h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
              <div>
                <h3 class="text-xl font-semibold mb-4">簡単</h3>
                <p>インターフェースは直感的で、誰でも簡単に使用できます。</p>
              </div>
              <div>
                <h3 class="text-xl font-semibold mb-4">高速</h3>
                <p>数秒でプロフェッショナルなデザインのLPを生成。</p>
              </div>
              <div>
                <h3 class="text-xl font-semibold mb-4">カスタマイズ</h3>
                <p>テンプレートを基に、自分だけのデザインを作成可能。</p>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials" class="bg-gray-100 py-16">
          <div class="container mx-auto text-center">
            <h2 class="text-3xl font-bold mb-8">お客様の声</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <blockquote>
                <p>「AILPCのおかげで、私たちのビジネスが大きく伸びました！」</p>
                <cite>- 山田 太郎</cite>
              </blockquote>
              <blockquote>
                <p>「こんなに簡単に素晴らしいLPが作れるなんて！」</p>
                <cite>- 佐藤 次郎</cite>
              </blockquote>
            </div>
          </div>
        </section>

        <section id="faq" class="py-16">
          <div class="container mx-auto text-center">
            <h2 class="text-3xl font-bold mb-8">よくある質問</h2>
            <div class="text-left max-w-xl mx-auto">
              <h3 class="text-xl font-semibold mb-4">Q: 価格はいくらですか？</h3>
              <p>A: 期間限定で3回まで無料です</p>
              <h3 class="text-xl font-semibold mb-4 mt-8">Q: カスタムデザインは可能ですか？</h3>
              <p>A: はい、プレミアムプランではカスタムデザインに対応しています。</p>
            </div>
          </div>
        </section>

        <section class="bg-blue-500 text-white py-16">
          <div class="container mx-auto text-center">
            <h2 class="text-3xl font-bold mb-8">今すぐ始めよう！</h2>
            <p class="text-lg mb-8">最適なランディングページを自動生成し、ビジネスを次のレベルに引き上げます。</p>
            <button
              onClick={handleSignIn}
              href="#" class="bg-white text-blue-500 px-6 py-2 rounded-full hover:bg-gray-200">Googleではじめる</button>
          </div>
        </section>

        <footer class="bg-gray-900 text-white py-8">
          <div class="container mx-auto flex justify-between items-center">
            <p>© 2023 AILPC. All rights reserved.</p>
            <div>
              <a href="https://twitter.com/ytiskw" class="ml-4 text-gray-400 hover:text-white">Twitter</a>
              {/* <a href="#" class="ml-4 text-gray-400 hover:text-white">Facebook</a> */}
            </div>
          </div>
        </footer>
      </div>
    );
  }

  // Render the main UI when user is authenticated
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white p-4 shadow-sm flex justify-between items-center">
        <h1 className="text-2xl font-extrabold text-gray-900">AILPC</h1>
        <button
          onClick={handleSignOut}
          className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Sign Out
        </button>
      </header>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
          <textarea
            placeholder="ここにサービス概要を入力してください（例：LPを自動生成するサービス）"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2 border-2"
            style={{ height: "100px" }}
          ></textarea>

          <div className="mt-6">
            <button
              onClick={handleGenerate}
              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${isLoading || !description.trim() ? 'bg-indigo-200 text-gray-400 cursor-not-allowed' : 'bg-indigo-600 text-white hover:bg-indigo-700'}`}
              disabled={isLoading || !description.trim()}
            >
              {isLoading ? "Loading..." : "Generate"}
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6">
        {generatedLPs?.map((lp, index) => (
          <div key={index} className="mb-2 flex">
            <button
              onClick={() => showPopup(lp.lpData)}
              className="flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {lp.description}
            </button>
            {index !== generatedLPs.length - 1 && <div className="mr-2"></div>}
            {/* This adds a margin to the right of each button except the last one */}
          </div>
        ))}
      </div>

      {showLP && (
        <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-xl w-full max-h-96 overflow-y-scroll">
              <button
                onClick={() => setShowLP(null)}
                className="absolute top-2 right-2 bg-gray-200 hover:bg-gray-300 text-black font-bold h-8 w-8 rounded-full flex items-center justify-center"
              >
                &times;
              </button>
              <pre className="mt-4 overflow-x-scroll whitespace-pre-wrap break-words">{showLP}</pre>
              <button
                onClick={() => navigator.clipboard.writeText(showLP)}
                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded block mx-auto"
              >
                Copy Code
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
}

export default App;
